export default {
  direction: 'ltr',
  palette: {
    type: 'light',
    primary: {
      main: '#1F191A'
    },
    secondary: {
      main: '#FF3A3C'
    },

  },
};