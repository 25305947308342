import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyD8tWQ68T0_Bf39mJxUT7zxHioS_UB81cA",
    authDomain: "bts-demo-app.firebaseapp.com",
    databaseURL: "https://bts-demo-app.firebaseio.com",
    projectId: "bts-demo-app",
    storageBucket: "bts-demo-app.appspot.com",
    messagingSenderId: "274346347122",
    appId: "1:274346347122:web:7c9fb25698fe358ef7d0eb"
};

export const firebaseImpl = firebase.initializeApp(firebaseConfig);
export const firebaseDatabase = firebase.database();