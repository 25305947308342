export default {
    direction: 'ltr',
    palette: {
        type: 'dark',
        primary: {
            main: '#FF3A3C'
        },
        secondary: {
            main: '#1F191A'
        },

    },
};